<template>
  <div class="content-wrapper">
    <div class="attendance-application main-content">
      <div class="page-header">
        <h1>Submit Attendance Application</h1>
        <AttendanceApplicationForm :viewMode="false":eventName="'createApplication'" @createApplication="createApplication"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import AttendanceApplicationForm from '@/components/hr/AttendanceApplicationForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'CreateAttendanceApplication',
  components: {
    AttendanceApplicationForm,
  },
  methods:{
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async createApplication(form){
      const loginInfo = Common.getLoginInfo();
      const applicationInfo = {
        date: dayjs(form.date).unix(),
        startTime: dayjs(form.startTime).unix(),
        endTime: dayjs(form.endTime).unix(),
        status: form.status,
        reason: form.reason,
      };
      try{
        const application = await Hr.createAttendanceApplication(this.apiUrl, applicationInfo, loginInfo);
        this.$router.push('/hr/attendance/applications');
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.application-list{
  td{
    text-transform: capitalize;
  }
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
